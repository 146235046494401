exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-autorzy-js": () => import("./../../../src/pages/autorzy.js" /* webpackChunkName: "component---src-pages-autorzy-js" */),
  "component---src-pages-bezplatne-materialy-dziennik-elektroniczny-js": () => import("./../../../src/pages/bezplatne-materialy/dziennik-elektroniczny.js" /* webpackChunkName: "component---src-pages-bezplatne-materialy-dziennik-elektroniczny-js" */),
  "component---src-pages-bezplatne-materialy-js": () => import("./../../../src/pages/bezplatne-materialy.js" /* webpackChunkName: "component---src-pages-bezplatne-materialy-js" */),
  "component---src-pages-bezplatne-materialy-piosenki-js": () => import("./../../../src/pages/bezplatne-materialy/piosenki.js" /* webpackChunkName: "component---src-pages-bezplatne-materialy-piosenki-js" */),
  "component---src-pages-bezplatne-materialy-plakaty-js": () => import("./../../../src/pages/bezplatne-materialy/plakaty.js" /* webpackChunkName: "component---src-pages-bezplatne-materialy-plakaty-js" */),
  "component---src-pages-bezplatne-materialy-poradniki-metodyczne-js": () => import("./../../../src/pages/bezplatne-materialy/poradniki-metodyczne.js" /* webpackChunkName: "component---src-pages-bezplatne-materialy-poradniki-metodyczne-js" */),
  "component---src-pages-bezplatne-materialy-roczny-rozklad-materialu-js": () => import("./../../../src/pages/bezplatne-materialy/roczny-rozklad-materialu.js" /* webpackChunkName: "component---src-pages-bezplatne-materialy-roczny-rozklad-materialu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-podreczniki-przedszkole-js": () => import("./../../../src/pages/podreczniki/przedszkole.js" /* webpackChunkName: "component---src-pages-podreczniki-przedszkole-js" */),
  "component---src-pages-podreczniki-szkola-podstawowa-klasy-1-4-js": () => import("./../../../src/pages/podreczniki/szkola-podstawowa-klasy-1-4.js" /* webpackChunkName: "component---src-pages-podreczniki-szkola-podstawowa-klasy-1-4-js" */),
  "component---src-pages-podreczniki-szkola-podstawowa-klasy-5-8-js": () => import("./../../../src/pages/podreczniki/szkola-podstawowa-klasy-5-8.js" /* webpackChunkName: "component---src-pages-podreczniki-szkola-podstawowa-klasy-5-8-js" */),
  "component---src-pages-podreczniki-szkola-ponadpodstawowa-js": () => import("./../../../src/pages/podreczniki/szkola-ponadpodstawowa.js" /* webpackChunkName: "component---src-pages-podreczniki-szkola-ponadpodstawowa-js" */)
}

